/**
 * @Author: Quxiaoya
 * @Date: 2019/06/25-15:36
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:30
 */

import {getVersionCode} from 'ssr-common/util/webview/ua';

export default context => ({

    data() {
        let ua;
        if (context) {
            ua = context.userAgent;
        } else if (this.$isServer && this.$ssrContext) {
            ua = this.$ssrContext.userAgent;
        } else if (!this.$isServer) {
            ua = window.navigator.userAgent;
        }

        const versionCode = getVersionCode(ua);

        return {ua, versionCode};
    }

});
