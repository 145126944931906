/*
 * @Author: ruiwang
 * @Date: 2020-04-29 16:50:41
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-04-19 11:12:19
 */

import {
    isEasyCashLenderNative,
    isEasyCashNative
} from 'ssr-common/util/webview/ua';

import parseUa from 'easycash/common/mixin/parse-ua';
import Agreement from 'easycash/common/resource/agreement';
import Loan from 'easycash/common/resource/loan';
import Mustache from 'easycash/common/util/mustache';

import serverPrefetch from './server-prefetch';

const keys = 'app_config.is_ojk_demo_user';

export default ({query}) => ({
    mixins: [serverPrefetch('agreement'), parseUa()],

    data() {
        return {
            defaultTitle: '',
            nowIndex: 0,
            atBottom: false
        };
    },

    title() {
        const {contentData: {title} = {}, defaultTitle} = this;

        return title || defaultTitle;
    },

    async mounted() {
        if (this.isBottom) {
            window.addEventListener('scroll', this.handleScroll);
        }

        const {scrollEnd} = this.$route.query;
        if (scrollEnd) {
            window.scrollTo(0, 100000);
        }
    },

    beforeDestroy() {
        if (this.isBottom) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },

    computed: {
        isBottom() {
            return this.agreement?.notUser;
        },

        isRaw() {
            const {params: {key}} = this.$route;

            return key === 'raw';
        },

        confirmBtnVisible() {
            const {content, $route: {query: {confirm = ''}}} = this;

            return content && confirm.toLowerCase() === 'true';
        },

        signBtnVisible() {
            const {$route: {query: {sign}}} = this;

            return sign === '1';
        },

        content() {
            return this.contentData.content;
        },

        responseDataValid() {
            return !!this.content;
        },

        titleList() {
            const {agreement} = this;
            const {agreementList} = agreement;

            return agreementList?.map?.(item => item.displayTitle) ?? [];
        },

        contentData() {
            const {nowIndex, agreement} = this;
            const {agreementList} = agreement;

            return agreementList?.[nowIndex] ?? {};
        },

        isEasycash() {
            return isEasyCashNative(this.ua);
        },

        isInvestaja() {
            return isEasyCashLenderNative(this.ua);
        }
    },

    methods: {
        handleScroll() {
            const {scrollTop, clientHeight, scrollHeight} = document.documentElement || document.body;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                this.atBottom = true;
            }
        },

        async fetchFactory(request, headers, params) {
            const {data: {body}} = await request({params, headers});

            return body;
        },

        dealBody(body) {
            const originBody = body?.agreementList ?? [body];

            return originBody.map(item => {
                const {pageTitle: title, values, nameList, imageUrlList, displayTitle} = item;
                let {content} = item;
                if (values) {
                    content = Mustache.render(content, {
                        ...values,
                        NAME_LIST: nameList,
                        IMAGE_URL_LIST: imageUrlList
                    });
                }

                return {content, title, displayTitle};
            });
        },

        async getMainContent(to, header) {
            try {
                const {
                    query: {
                        key: queryKey,
                        tradeRecordId,
                        id,
                        userId,
                        extraOptions,
                        isAgreementTemplate,
                        businessId,
                        businessType,
                        ...queryParams
                    },
                    params: {key: paramsKey}
                } = to || this.$route;
                const key = paramsKey || queryKey;
                let body;

                if (!id && !tradeRecordId && !key) return {};

                const params = {
                    key,
                    id: tradeRecordId || id,
                    userId,
                    isAgreementTemplate: isAgreementTemplate === 'true'
                };

                if (extraOptions) {
                    body = await this.fetchFactory(
                        Agreement.demoAgreement,
                        header,
                        {...queryParams, ...params}
                    );
                } else if (this.isRaw && (this.isDebt || this.isAutoDebt) && this.isInvestaja) {
                    body = await this.fetchFactory(
                        Agreement.getDebtRaw,
                        header,
                        this.$route.query
                    );
                } else if (this.isRaw) {
                    body = await this.fetchFactory(
                        Agreement.getRaw,
                        header,
                        {businessId, businessType}
                    );
                } else {
                    body = await this.fetchFactory(query, header, params);
                }

                if (body) {
                    return this.dealBody(body);
                }
            } catch (err) {
                YqgReporter.fatalException(err);
            }

            return [];
        },

        async getShunt(headers) {
            if (this.isEasycash) {
                try {
                    const {
                        data: {body}
                    } = await Loan.generalConfig({
                        params: {keys},
                        hideLoading: true,
                        hideToast: true,
                        headers
                    });
                    const configItem = body?.configs?.find?.(({configName}) => configName === keys);

                    return {notUser: configItem?.configValue};
                } catch (error) {
                    // ignore
                }
            }

            return {notUser: false};
        },

        async initFetch({to, header}) {
            const [agreementList, other] = await Promise.all([
                this.getMainContent(to, header),
                this.getShunt(header)
            ]);

            return {
                agreementList,
                ...other
            };
        }
    }
});
