import Mustache from 'mustache';
var _a = Mustache.Context, prototype = _a.prototype, _b = _a.prototype, originLookup = _b.originLookup, lookup = _b.lookup;
if (!originLookup)
    Object.assign(prototype, { originLookup: lookup });
var FormatterMap = {
    percent: function (value) {
        if (!value && value !== 0)
            return '';
        var temp = Math.floor(value * 10000) / 100;
        return "".concat(temp, "%");
    }
};
var applyFilter = function (expression, filterStr) {
    var filterName = filterStr.replace(/\s/g, '');
    if (!filterName)
        return expression;
    if (!FormatterMap[filterName])
        return expression;
    return FormatterMap[filterName](expression);
};
Object.assign(prototype, {
    lookup: function (name) {
        var self = this;
        var _a = name.split('|').map(function (item) { return item.trim(); }), expression = _a[0], _b = _a[1], filter = _b === void 0 ? '' : _b;
        var value = self.originLookup(expression);
        return applyFilter(value, filter);
    }
});
export default Mustache;
