/*
 * @Author: ruiwang
 * @Date: 2020-04-29 14:41:09
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-04-13 17:13:02
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    getByKey: {
        url: '/api/operation/agreement/getByKey',
        method: 'get'
    },

    getContentByKey: {
        url: '/api/operation/agreement/getContentByKey',
        method: 'get'
    },

    getRaw: {
        url: '/api/operation/agreement/raw',
        method: 'get'
    },

    getDebtRaw: {
        url: '/api/operation/agreement/debtMatch/raw',
        method: 'get'
    },

    demoAgreement: {
        url: '/api/operation/agreement/loan/demoAgreement',
        method: 'get'
    }
};

export default Resource.create(api);
