/*
 * @Author: chengyuzhang
 * @Date: 2020-10-14 18:19:55
 * @Last Modified by: lijiahang
 * @Last Modified time: 2023-11-02 14:13:47
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    queryCalcCreditsStatus: {
        url: '/api/loan/queryCalcCreditsStatus',
        method: 'get'
    },

    getCollegeList: {
        url: '/api/loan/education/getIdnCollegeList',
        method: 'get'
    },

    getStudyProgramList: {
        url: '/api/loan/education/getIdnStudyProgramList',
        method: 'get'
    },

    uploadEducation: {
        url: '/api/loan/extraInfo/uploadEducation',
        method: 'post'
    },

    upload: {
        url: '/api/loan/extraInfo/upload',
        method: 'post'
    },

    summary: {
        url: '/api/loan/invitation/summary',
        method: 'get'
    },

    rewards: {
        url: '/api/loan/invitation/rewards',
        method: 'get'
    },

    list: {
        url: '/api/loan/invitation/inviteeList',
        method: 'get'
    },

    walletHome: {
        url: '/api/loan/wallet/home',
        method: 'get'
    },

    listPaymentCredentials: {
        url: '/api/loan/listPaymentCredentials',
        method: 'get'
    },

    sendVerificationWithoutCaptcha: {
        url: '/api/loan/mobile/sendVerificationWithoutCaptcha',
        method: 'post'
    },

    withdraw: {
        url: '/api/loan/wallet/withdraw',
        method: 'post'
    },

    flows: {
        url: '/api/loan/wallet/flows',
        method: 'get'
    },

    me: {
        url: '/api/loan/me',
        method: 'get'
    },

    getAuthOptionList: {
        url: '/api/loan/getAuthOptionList',
        method: 'get'
    },

    getLoanUse: {
        url: '/api/loan/getLoanUse',
        method: 'get'
    },

    getIndustryStandardTree: {
        url: '/api/loan/getIndustryStandardTree',
        method: 'get'
    },

    getRelationshipListV2: {
        url: '/api/loan/getRelationshipListV2',
        method: 'get'
    },

    uploadPersonalInfo: {
        url: '/api/loan/uploadPersonalInfo',
        method: 'post'
    },

    getLoanAmountList: {
        url: '/api/loan/getLoanAmountList',
        method: 'get'
    },

    uploadFrontLivingIdCardImageV2: {
        url: '/api/loan/uploadFrontLivingIdCardImageV2',
        method: 'post',
        formData: true
    },

    uploadIdentityInfoV2: {
        url: '/api/loan/uploadIdentityInfoV2',
        method: 'post',
        link: true
    },

    getQiniuUploadToken: {
        url: '/api/loan/getQiniuUploadToken',
        method: 'get'
    },

    uploadLivingInfo: {
        url: '/api/loan/uploadLivingInfo',
        method: 'post'
    },

    creditsInfo: {
        url: '/api/loan/creditsInfo',
        method: 'get'
    },

    getHouseTypeList: {
        url: '/api/loan/getHouseTypeList',
        method: 'get'
    },

    invitationId: {
        url: '/api/loan/invitation/invitationId',
        method: 'get'
    },

    rank: {
        url: '/api/loan/invitation/rank',
        method: 'get'
    },

    registerInvitationId: {
        url: '/api/loan/invitation/register/invitationId',
        method: 'get'
    },

    generalConfig: {
        url: '/api/generalConfig',
        method: 'get'
    },

    getAbExperiment: {
        url: '/api/bizCheck/v2/result',
        method: 'post'
    },

    signUp: {
        url: '/api/signature/clickHandWritten/upload',
        method: 'post'
    },

    getSubInvitationInfo: {
        url: '/api/loan/invitation/getSubInvitationInfo',
        method: 'get'
    },

    getCalcFee: {
        url: '/api/official/calcFee',
        method: 'get'
    }
};

export default Resource.create(api);
